* {
    box-sizing: border-box;
}

/* .whiteboard {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
} */

#whiteboard::-webkit-scrollbar {
    display: none;
}

#whiteboard {
    background-color: #fff;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.colors {
    position: fixed;
    top: 95%;
    margin-left: 30px;


}

.color {
    display: inline-block;
    height: 24px;
    width: 30px;
}

.color.black {
    background-color: black;
}

.color.red {
    background-color: red;
}

.color.green {
    background-color: green;
}

.color.blue {
    background-color: blue;
}

.color.yellow {
    background-color: yellow;
}

.color.white {
    background-color: white;
    /* cursor: url(https://legacy.imagemagick.org/Usage/canvas/gradient_fx_spherical.gif),progress; */


}

/* .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border: 1px solid black;
    width: 80%;
} */

.btn {
    /* border: 2px solid gray; */
    color: gray;
    background-color: white;
    padding: 8px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: bold;
}

.upload-btn-wrapper :hover {
    cursor: pointer;
}

/* .upload-btn-wrapper input[type='file'] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
} */

.upload-btn-wrapper input[type='file']:hover {
    cursor: pointer;
}

.box {
    /* border:2px solid black; */
    height: 70px;
    width: 70px;

    position: fixed;
    top: 30px;
    left: 90px;
    width: 30px;
    background-image: url("../../../assets/images/easy-to-use-flat-icon-of-eraser-vector.jpg");
    background-repeat: no-repeat;
    background-size: contain;

}

.backButton {
    height: 25px;
    width: 25px;

    position: fixed;
    top: 30px;
    left: 30px;
    background-image: url("../../../assets/images/arrow.png");
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
}