.authLayout-wrapper {
    height: 100%;
}

.static-container {
    background-image: url('../../../assets/images/login.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}



@media only screen and (max-width: 600px) {
    .static-container {
        display: none;
    }
}
