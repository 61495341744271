.css-lx4zqm-MuiLinearProgress-root {
    border-radius: 16px;
    height: 10px;
}

.css-vp5etv {
    background-color: #1FCC92 !important;
}

.css-1gzba2z-MuiPaper-root-MuiDialog-paper {
    width: 370px !important;
    padding-bottom: 20px;
    padding-top: 20px;
    border-radius: 20px;
}
