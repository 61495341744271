.react-player>video {
    border-radius: 10px;
    overflow: hidden;
}

ul,
ol {
    margin-left: 30px;
}

.ql-size-small {
    /* font-size: 0.75em; */
    font-size: 10px !important;
}

.ql-size-normal {
    /* font-size: 1.5em; */
    font-size: 15px !important;
}

.ql-size-large {
    /* font-size: 1.5em; */
    font-size: 20px !important;
}

.ql-size-huge {
    /* font-size: 2.5em; */
    font-size: 25px !important;
}

.styles-module_Editext__edit_button__310_J {
    border: none !important;
    background: transparent !important;
    padding: 0;
}

.styles-module_Editext__button__sxYQX {
    padding: 0 !important;
}

.EmojiPickerReact .Flex.FlexRow {
    display: none;
}

.react-player>div>iframe {
    height: 400px !important;
    border-radius: 8px;
}

.emoji {
    transition: transform .2s;
}

.emoji:hover {
    transform: scale(1.5);
}

.css-tmtkc6-MuiBackdrop-root {
    border-radius: 10px;
}

.css-1aa8fnn {
    background: black;
    border-radius: 10px;
}
